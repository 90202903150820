import React, { useContext, useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import "./MyTimesheet.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Input from "@mui/joy/Input";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Form from "react-bootstrap/Form";
import { format, parse } from "date-fns";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { ColorContext } from "../../ColorContext";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";

dayjs.extend(utc);
dayjs.extend(timezone);
function MyTimesheet() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [load, setLoading] = useState(false);
  const [timesheet, setTimesheet] = useState([]);
  const [projects, setProjects] = useState([]);
  const [taskstatus, setTaskstatus] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [filterDate, setFilterDate] = useState(new Date());
  const [filteredTimesheet, setFilteredTimesheet] = useState([]);
  const { textColor } = useContext(ColorContext);

  function showFilterS() {
    setShowFilter(!showFilter);
  }
  useEffect(() => {
    setLoading(true);
  }, []);

  const fetchTimesheet = useCallback(async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/my_timesheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      if (result.success === true) {
        setTimesheet(result.timesheet_records);
        setProjects(result.project_list);
        setTaskstatus(result.task_list);
        setLoading(false);
      } else {
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [employee_id]);

  const submitTimesheet = async (e) => {
    e.preventDefault();
    const confirmSubmit = window.confirm("Are you sure you want to submit your timesheet?");
    if (!confirmSubmit) return;
    try {
      const Rows = rows.map((row) => ({
        ...row,
        date: dayjs(row.date).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"),
        timeFrom: dayjs(row.timeFrom).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"),
        timeTo: dayjs(row.timeTo).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"),
      }));
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, Rows };
      const response = await fetch(`${configData.SERVER_URL}/add_timesheet`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);

      if (result.success === true) {
        Swal.fire({ icon: "success", title: "Success", text: "Timesheet Submitted Successfully", });
        fetchTimesheet();
        setRows([defaultRow]);
      } else {
        alert("Submission failed. Please try again.");
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting.");
    }
  };

  useEffect(() => {
    fetchTimesheet();
  }, [fetchTimesheet]);

  const getFormattedDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const defaultRow = {
    id: 1,
    date: new Date(),
    project: "",
    task: "",
    status: "",
    timeFrom: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
    timeTo: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
    hours: "",
    comments: "",
  };
  const [rows, setRows] = useState([defaultRow]);
  const addRow = () => {
    if (rows.length === 0 || rows[rows.length - 1].project) {
      const newRow = {
        id: rows.length + 1,
        date: new Date(),
        project: "",
        task: "",
        status: "",
        timeFrom: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
        timeTo: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
        hours: "",
        comments: "",
      };
      setRows([...rows, newRow]);
    } else {
      alert("Please select Project before adding a new row.");
    }
  };
  const calculateHours = (timeFrom, timeTo) => {
    if (timeFrom && timeTo) {
      let diff = timeTo.diff(timeFrom, "minute");
      if (diff < 0) {
        diff += 1440;
      }

      return (diff / 60).toFixed(2);
    }
    return "";
  };
  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    if (field === "timeFrom" || field === "timeTo") {
      const timeFrom = newRows[index]["timeFrom"];
      const timeTo = newRows[index]["timeTo"];
      newRows[index]["hours"] = calculateHours(timeFrom, timeTo);
    }
    setRows(newRows);
  };
  const deleteLastRow = () => {
    const newRows = [...rows];
    newRows.pop();
    setRows(newRows);
  };

  const convertDecimalToHHMMSS = (decimalHours) => {
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy");
  };
  const formatTime = (time) => {
    return time.format("hh:mm A");
  };
  const deleteRowById = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this row?");
    if (confirmDelete) {
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    }
  };

  return (
    <>
      {load ? (
        <div className="main-panel">
          <LoadingComponent />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="" style={{ gap: "10px", textAlign: "left",paddingBottom:"40px"}}>
                    {/* <div className="showFilter-btn" onClick={showFilterS}>
                      <img
                        src="filter.jpg"style={{height: 15,margin: "7px 0px 10px 16px",}}
                        alt="Filter icon"
                      />
                      <p
                        htmlFor="options"
                        style={{
                          marginBottom: "0px",
                          lineHeight: "29px",
                        }}
                      >
                        Filter
                      </p>
                    </div> */}
                    <div
                      className={`show-filter-timesheet mt-3 ${showFilter ? "show-filter" : ""
                        }`}
                    >
                      <DatePicker
                        reduceAnimations
                        selected={filterDate}
                        label="Filter-Date"
                        onChange={(date) => setFilterDate(date)}
                        required
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                  <div className="tabs-container">
                    <NavLink
                      to="/MyTimesheet"
                      className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                      style={{ textDecoration: "none" }}
                    >
                      My Timesheet
                    </NavLink>
                    <NavLink
                      to="/PendingApproval"
                      className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                      style={{ textDecoration: "none" }}
                    >
                      Pending Approval
                    </NavLink>
                    <NavLink
                      to="/Approved"
                      className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                      style={{ textDecoration: "none" }}
                    >
                      Approved
                    </NavLink>
                  </div>
                  <div className="">
                    <div className="atable">
                      <Form onSubmit={submitTimesheet}>
                        <table id="basicTable" className="basic-table">
                          <thead className="all-tables-head">
                            <tr
                              role="row"
                              style={{ backgroundColor: textColor }}
                            >
                              <th className="center">
                                <span> Sl.No </span>
                                <span />
                              </th>
                              <th className="center">
                                <span> Date </span>
                                <span />
                              </th>
                              <th className="center">
                                <span> Project </span>
                                <span />
                              </th>
                              <th className="center">
                                <span> Task Description</span>
                                <span />
                              </th>
                              <th className="center">
                                <span>Task Status </span>
                                <span />
                              </th>
                              <th className="center multi-table" colSpan="2">
                                <>
                                  <table>
                                    <tbody>
                                      <tr
                                        role="row"
                                      >
                                        <td colSpan="2">
                                          <span> Duration </span>
                                        </td>
                                      </tr>
                                      <tr
                                        role="row"
                                      >
                                        <td style={{ width: "50%" }}>
                                          <span> From </span>
                                        </td>
                                        <td>
                                          <span> To </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              </th>
                              <th className="center">
                                <span>Hours Spend</span>
                                <span />
                              </th>
                              <th className="center timesheet-comments">
                                <span> Comments </span>
                                <span />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTimesheet.map((record, index) => (
                              <tr key={index} className="">
                                <td className="">{index + 1}</td>
                                <td className="">{record[0]}</td>
                                <td className="">{record[1]}</td>
                                <td className="">{record[2]}</td>
                                <td className="">{record[3]}</td>
                                <td className="">{record[4]}</td>
                                <td className="">{record[5]}</td>
                                <td className="">
                                  {convertDecimalToHHMMSS(record[6])}
                                </td>
                                <td className="">{record[7]}</td>
                              </tr>
                            ))}
                            {rows.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <DatePicker
                                    // autoFocus
                                    sx={{ maxWidth: 390 }}
                                    reduceAnimations
                                    value={dayjs(row.date)}
                                    format="DD/MM/YYYY"
                                    label="Date"
                                    onChange={(date) => handleChange(index, "date", date)}
                                    required
                                    slots={{ openPickerIcon: () => null }}
                                  />
                                </td>
                                <td>
                                  <Box sx={{ minWidth: 100 }}>
                                    <FormControl fullWidth>
                                      <InputLabel id="label-project">
                                        Project*
                                      </InputLabel>
                                      <Select
                                        labelId="label-project"
                                        id="project"
                                        name="project"
                                        value={row.project}
                                        label="Project"
                                        onChange={(e) => handleChange(index, "project", e.target.value)}
                                        required
                                      >
                                        {projects.map((project, idx) => (
                                          <MenuItem
                                            key={idx}
                                            value={project[1]}
                                          >
                                            {project[0]}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                                <td>
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    // InputProps={{
                                    //   sx: {
                                    //     height: "54px",
                                    //   },
                                    // }}
                                    // size="small"
                                    // label="Comments"
                                    // variant="filled"
                                    value={row.task}
                                    onChange={(e) => handleChange(index, "task", e.target.value)}
                                    required
                                  />
                                </td>
                                {/* <td>
                                  <Box sx={{ minWidth: 100 }}>
                                    <FormControl fullWidth>
                                      <InputLabel id="label-task">
                                        Task
                                      </InputLabel>
                                      <Select
                                        labelId="label-task"
                                        name="task"
                                        value={row.task}
                                        label="Task"
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "task",
                                            e.target.value
                                          )
                                        }
                                        disabled={!row.project}
                                      >
                                        {projects
                                          .find(
                                            (p) => p[1] === row.project
                                          )?.[2]
                                          .map((task, idx) => (
                                            <MenuItem key={idx} value={task[1]}>
                                              {task[0]}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td> */}
                                <td>
                                  <Box sx={{ minWidth: 100 }}>
                                    <FormControl fullWidth>
                                      <InputLabel id="label-status">
                                        Status
                                      </InputLabel>
                                      <Select
                                        labelId="label-status"
                                        name="status"
                                        value={row.status}
                                        label="Status"
                                        onChange={(e) =>
                                          handleChange(
                                            index,
                                            "status",
                                            e.target.value
                                          )
                                        }
                                        // disabled={!row.task}
                                      >
                                        {/* {taskstatus.map((status, idx) => (
                                          <MenuItem
                                            key={idx}
                                            value={status["key"]}
                                          >
                                            {status["value"]}
                                          </MenuItem>
                                        ))} */}
                                        {/* <option value="">Select Task Status</option> */}
                                                  {Array.isArray(taskstatus) && taskstatus.length > 0 ? (
                                                    taskstatus.map((task) => (
                                                      <MenuItem key={task.id} value={task.id}>
                                                        {task.name}
                                                      </MenuItem>
                                                    ))
                                                  ) : (
                                                    <option disabled>No Task Status</option>
                                                  )}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                                <td>
                                  <TimePicker
                                    label="Time From"
                                    value={row.timeFrom}
                                    onChange={(newValue) => handleChange(index, "timeFrom", newValue)}
                                    format="hh:mm A"
                                    slots={{ openPickerIcon: () => null }}
                                  />
                                </td>
                                <td>
                                  <TimePicker
                                    label="Time To"
                                    value={row.timeTo}
                                    onChange={(newValue) => handleChange(index, "timeTo", newValue)}
                                    format="hh:mm A"
                                    slots={{ openPickerIcon: () => null }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    sx={{ maxWidth: 140 }}
                                    value={convertDecimalToHHMMSS(row.hours)}
                                    label="Hours"
                                  />
                                </td>
                                <td>
                                  <TextField
                                    sx={{ maxWidth: 140 }}
                                    value={row.comments}
                                    onChange={(e) => handleChange(index, "comments", e.target.value)}
                                  />
                                </td>
                                <IconButton
                                  onClick={() => deleteRowById(row.id)}
                                  // color="error"
                                  style={{ color: textColor }}
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="mt-3">
                          <Button
                            style={{ backgroundColor: textColor,color:"white"}}
                            onClick={addRow}
                            className="mx-2"
                            variant="contained"
                          >
                            Add Row
                          </Button>
                          <Button
                            style={{ backgroundColor: textColor,color:"white" }}
                            type="submit"
                            className="mx-2"
                            variant="contained"
                          >
                            Submit
                          </Button>
                          {/* <Button
                            style={{ backgroundColor: textColor }}
                            onClick={deleteLastRow}
                            className="mx-2"
                            variant="contained"
                          >
                            Delete Row
                          </Button> */}
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default MyTimesheet;
