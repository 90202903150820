import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import "./Sidebar.css";
import { ColorContext } from "../../ColorContext";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import eventEmitter from '../../Eventemitter';


function Sidebar() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [emplinfo, setEmplinfo] = useState({
    // company_image: null,
    company_link: "",
  });

  // useEffect(() => {
  //   const fetchCompanyDetails = async () => {
  //     try {
  //       const secretKey = configData.SECRET_Key;
  //       const requestData = { employee_id, secretKey };
  //       const response = await fetch(`${configData.SERVER_URL}/mycompany`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(requestData),
  //       });
  //       const data = await response.json();
  //       const result = JSON.parse(data.result);
  //       // console.log('result', result);
  //       if (result.success === true) {
  //         setEmplinfo({
  //           company_link: result.company_link,
  //           // company_image: result.company_image_url,
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   fetchCompanyDetails();
  // }, [employee_id]);

  const [activeIndex, setActiveIndex] = useState();

  const location = useLocation();

useEffect(() => {
  const pathToIndexMap = {
    "/": 1,
    "/mytimesheet": 7,
    "/myasset": 6,
    "/myexpenses": 9,
    "/myprofile": 4,
    "/companypolicies": 5,
    "/PendingApproval": 7,
    "/Approved" :7,
    "/MyTimesheet":7,
    "/mylearning":2,
    "/gallery":3,
  };

  const currentPath = location.pathname;
  const newIndex = pathToIndexMap[currentPath] || 1; // Default to Dashboard
  setActiveIndex(newIndex);
}, [location.pathname]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const { bgColor, textColor, pageColor } = useContext(ColorContext);


  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  useEffect(() => {
    const handleToggleSidebar = () => {
      setIsSidebarToggled((prevState) => !prevState);
    };

    eventEmitter.on('toggleSidebar', handleToggleSidebar);

    // Clean up the event listener on component unmount
    return () => {
      eventEmitter.off('toggleSidebar', handleToggleSidebar);
    };
  });


  return (
    <nav 
    className={`sidebar sidebar-offcanvas ${isSidebarToggled ? 'toggle-sidebar' : ''}`}
    // className='sidebar sidebar-offcanvas'
    id="sidebar"
    style={{ 
      "--text-color": textColor,
      "--bg-color": bgColor
    }}
    >
      
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <img
          src="company_logo.png"
          alt="Profile Pic"
          className="img-xs brand-logo-image "
        />
        {/* <a className="sidebar-brand brand-logo" href="/"  onClick={(e) => e.preventDefault()}>
              LOGO3
            </a> */}

        {/* <a className="sidebar-brand brand-logo-mini" href="/"  onClick={(e) => e.preventDefault()}>
          L
        </a> */}
      </div>
      <ul className="nav mt-5 menu-sidebar">
        <li
          key={1}
          className={`nav-item menu-items ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => handleItemClick(1)}
          style={activeIndex === 1 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/" className="nav-link">
            <span className="menu-icon">
              <i className="fas fa-home" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor  }}>
              Dashboard
            </span>
          </Link>
        </li>
        {/* <li
          key={2}
          className={`nav-item menu-items ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
          style={activeIndex === 2 ? { backgroundColor: bgColor } : {}}
        >
          <a className="nav-link" href="/" onClick={(e) => e.preventDefault()}>
            <span className="menu-icon">
              <i className="fas fa-tasks" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Tasks
            </span>
          </a>
        </li>
        <li
          key={3}
          className={`nav-item menu-items ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleItemClick(3)}
          style={activeIndex === 3 ? { backgroundColor: bgColor } : {}}
        >
          <a className="nav-link" href="/" onClick={(e) => e.preventDefault()}>
            <span className="menu-icon">
              <i className="fas fa-check-circle" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My Department
            </span>
          </a>
        </li> */}
        <li
          key={7}
          className={`nav-item menu-items ${activeIndex === 7 ? "active" : ""}`}
          onClick={() => handleItemClick(7)}
          style={activeIndex === 7 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/mytimesheet" className="nav-link">
            <span className="menu-icon">
              <i className="fa fa-calendar-alt" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My Timesheet
            </span>
          </Link>
        </li>
        {/* <li
          key={4}
          className={`nav-item menu-items ${activeIndex === 4 ? "active" : ""}`}
          onClick={() => handleItemClick(4)}
          style={activeIndex === 4 ? { backgroundColor: bgColor } : {}}
        >
          <a className="nav-link" href="/" onClick={(e) => e.preventDefault()}>
            <span className="menu-icon">
              <i className="fas fa-signal" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Performance
            </span>
          </a>
        </li> */}
        
        <li
          key={6}
          className={`nav-item menu-items ${activeIndex === 6 ? "active" : ""}`}
          onClick={() => handleItemClick(6)}
          style={activeIndex === 6 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/myasset" className="nav-link" >
            <span className="menu-icon">
              <i
                className="fa-solid fa-computer"
                style={{ color: textColor }}
              />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My Assets
            </span>
          </Link>
        </li>
        <li
          key={9}
          className={`nav-item menu-items ${activeIndex === 9 ? "active" : ""}`}
          onClick={() => handleItemClick(9)}
          style={activeIndex === 9 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/myexpenses" className="nav-link">
            <span className="menu-icon">
            <i className="fa-solid fa-indian-rupee-sign" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My Expenses
            </span>
          </Link>
        </li>
      
        {/* <li
          key={5}
          className={`nav-item menu-items ${activeIndex === 5 ? "active" : ""}`}
          onClick={() => handleItemClick(5)}
          style={activeIndex === 5 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/documents" className="nav-link">
            <span className="menu-icon">
              <i className="fa-solid fa-file" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Documents
            </span>
          </Link>
        </li> */}
        <li
          key={4}
          className={`nav-item menu-items ${activeIndex === 4 ? "active" : ""}`}
          onClick={() => handleItemClick(4)}
          style={activeIndex === 4 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/myprofile" className="nav-link">
            <span className="menu-icon">
              <i className="fa-solid fa-user" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My profile
            </span>
          </Link>
        </li>

        <li
          key={2}
          className={`nav-item menu-items ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
          style={activeIndex === 2 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/mylearning" className="nav-link">
            <span className="menu-icon">
            <i className="fas fa-book-open" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              My Learning
            </span>
          </Link>
        </li>

        <li
          key={5}
          className={`nav-item menu-items ${activeIndex === 5 ? "active" : ""}`}
          onClick={() => handleItemClick(5)}
          style={activeIndex === 5 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/companypolicies" className="nav-link">
            <span className="menu-icon">
              <i className="fa-solid fa-laptop" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Company policies
            </span>
          </Link>
        </li>
        <li
          key={3}
          className={`nav-item menu-items ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleItemClick(3)}
          style={activeIndex === 3 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/gallery" className="nav-link">
            <span className="menu-icon">
            <i className="far fa-image" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Gallery
            </span>
          </Link>
        </li>

          <li
          key={8}
          className={`nav-item menu-items settings-fixed ${activeIndex === 8 ? "active" : ""}`}
          onClick={() => handleItemClick(8)}
          style={activeIndex === 8 ? { backgroundColor: bgColor } : {}}
        >
          <Dropdown>
            <MenuButton
              style={{
                width: "92%",
                padding: "0px",
                border: 0
              }}
              className="MenuButton"
            >
              <span
                className="nav-link" 
                // to="/myasset"
                style={{
                  marginRight: "auto",
                }}
              >
                <span className="menu-icon">
                  <i
                    className="fa-solid fa-gears"
                    style={{ color: textColor }}
                  />
                </span>
                <span className="menu-title " style={{ color: textColor }}>
                  Settings
                </span>
              </span>
            </MenuButton>
            <Menu>
              <MenuItem>
                <span
                  className=""
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  Color Customization
                </span>
              </MenuItem>
            </Menu>
          </Dropdown>
        </li>

      </ul>
    </nav>
  );
}

export default Sidebar;
