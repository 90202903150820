import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../../Login_Page/Login_Page/LoginPage";
import "./LeaveTable.css";
import { ColorContext } from "../../../ColorContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import configData from "../../../../src/config.json";
import Swal from "sweetalert2";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LoadingComponent from "../../loading_component";
import TableLoading from "./TableLoading";

const LeaveTable = () => {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [load, setLoading] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const { bgColor, textColor } = useContext(ColorContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [show, setShow] = useState(false);
  const [showleave, setShowLeave] = useState(false);
  const [formData, setFormData] = useState({
    attachment: null,
  });
  const handleClose = () => {
    setShow(false);
    setShowLeave(false);
  };

  const handleShowLeave = (leave) => {
    setSelectedRow(leave);
    setShowLeave(true);
  };

  const fetchLeaves = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/myleaves`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();      
      const result = JSON.parse(data.result);
      if (result.success === true) {
        setLeaves(result.leaves);
        setLoading(false);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    fetchLeaves();
  }, [employee_id]);

  const [fetchleave, setFetchleave] = useState(false);
  
  const handleUpload = async (e) => {
    console.log("===========");
    e.preventDefault();
    e.stopPropagation();
    if (fetchleave) return;
    setFetchleave(true); 
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { formData, employee_id, secretKey,leave_id:selectedRow[8] };
      const response = await fetch(`${configData.SERVER_URL}/addDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to create leave");
      }
      if (response) {
        const data = await response.json();
        const result = JSON.parse(data.result);
        if (result.success === true) {
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setFetchleave(false);
    }
  };
  const handleChange = (e) => {
    const { name, checked, files, value } = e.target;
    if (e.target.name === "attachment") {
      const base64DataArray = [];
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64DataArray.push(reader.result);
          if (base64DataArray.length === files.length) {
            setFormData({ ...formData, [name]: base64DataArray });
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };
  return (
    <>
      {load ? (
        // <div className="main-panel">
        <TableLoading />
      ) : (
        // </div>
        <div className="status_table">
          <table className="leave_status_table">
            <thead>
              <tr style={{ background: textColor }}>
                <th>S.NO</th>
                <th>LEAVE TYPE</th>
                <th>DATE FROM</th>
                <th>DATE TO</th>
                <th>DURATION</th>
                <th>STATUS</th>
                {/* {shouldShowRaiseColumn && <th>RAISE</th>} */}
              </tr>
            </thead>
            <tbody>
              {leaves.map((leave, index) => (
                <tr
                  key={index}
                  role="button"
                  onClick={(e) => {
                    handleShowLeave(leave);
                  }}
                >
                  <td> {index + 1}</td>
                  <td>{leave[0]}</td>
                  <td>{leave[1]}</td>
                  <td>{leave[2]}</td>
                  <td>{leave[3]}</td>
                  <td>
                    <span
                      className={
                        leave[4] === "Approved"
                          ? "op_a"
                          : leave[4] === "Pending"
                          ? "op_p"
                          : leave[4] === "AL-Rejected"
                          ? "op_al"
                          : "op_r"
                      }
                    >
                      {leave[4]}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <>
            <Modal
              show={showleave}
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              className="leave-info-modal"
              // size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {selectedRow && selectedRow[0]}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="grid-example">
                <Container>
                  <Row>
                    <Col xs={6} md={5}>
                      Dates
                    </Col>
                    <Col xs={6} md={7}>
                      From {selectedRow && selectedRow[1]}
                      To {selectedRow && selectedRow[2]}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      Duration
                    </Col>
                    <Col xs={6} md={7}>
                      {selectedRow && selectedRow[3]}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} md={5}>
                      Description
                    </Col>
                    <Col xs={6} md={7}>
                      {selectedRow && selectedRow[7]}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} md={5}>
                      Status
                    </Col>
                    <Col xs={6} md={7}>
                      {selectedRow && (
                        <span
                          className={
                            selectedRow[4] === "Approved"
                              ? "op_a"
                              : selectedRow[4] === "Pending"
                              ? "op_p"
                              : "op_r"
                          }
                        >
                          {selectedRow[4]}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      Add Document
                    </Col>
                    <Col xs={6} md={7}>
                    <td style={{ width: "100%", textAlign: "left" ,border:"none"}}>
                            <input
                              type="file"
                              id="attachment"
                              name="attachment"
                              accept=".pdf, image/*"
                              onChange={handleChange}
                              multiple
                            />
                          </td>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleUpload}>Upload</Button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      )}
    </>
  );
};
export default LeaveTable;
