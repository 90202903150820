import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import './My_Learning.css';

function MyLearning() {
  const [depart, setDepart] = useState([]);
  const [activeFile, setActiveFile] = useState(null);
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData?.employee_id || 0;
  const [load, setLoading] = useState(true);

  useEffect(() => {
    if (employee_id) fetchCourses();
  }, [employee_id]);

  const fetchCourses = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/mylearning`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(requestData),
      });
      const responseData = await response.json();
      console.log("res-------------", responseData);
      const result = JSON.parse(responseData.result);
      if (result.success) {
        setDepart(result.course_data || []);
      } else {
        console.error("Error fetching courses:", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (base64Data, fileName) => {
    const link = document.createElement("a");
    link.href = `data:application/octet-stream;base64,${atob(base64Data)}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleDropdown = (event, file) => {
    event.stopPropagation();
    setActiveFile(activeFile === file ? null : file);
  };

  useEffect(() => {
    const handleClickOutside = () => setActiveFile(null);
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      {load ? (
      <div className="">
      </div>
    ) : (
        <div className="learning-container">
          <div className="al_holi">
            <h2 className="al_holi" style={{paddingBottom:"30px"}}>My Learning</h2>
          </div>
          {depart.length > 0 ? (
            <div className="course-list">
              {depart.map((course, index) => (
                <div key={index} className="course-item-container">
                  <div onClick={(event) => toggleDropdown(event, course)} className="course-item">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                      alt="document"
                      width="30px"
                    />
                    <p className="course-name">{course.name}</p>
                  </div>
                  {activeFile === course && (
                    <div className="overlay-button">
                      <button
                        onClick={() => window.open(`data:application/pdf;base64,${atob(course.file)}`)}
                        className="overlay-btn open-btn"
                      >
                        Open
                      </button>
                      <button onClick={() => downloadFile(course.file, course.name)} className="overlay-btn download-btn">
                        Download
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="no-policies-message">No courses available for your department</p>
          )}
        </div>
      )}
    </>
  );
}

export default MyLearning;
