import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";

function Gallery() {
  const [galleryData, setGalleryData] = useState({});
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    fetchGallery();
  }, [employee_id]);

  const fetchGallery = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/gallery`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      if (data.result && data.result.success) {
        setGalleryData(data.result.gallery_data || {});
      } else {
        console.error("Error fetching gallery:", data.result?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const openImageModal = (tag, idx) => {
    setSelectedTag(tag);
    setSelectedIndex(idx);
    setSelectedImage(galleryData[tag][idx]?.file);
  };

  const nextImage = () => {
    if (!selectedTag) return;
    const images = galleryData[selectedTag];
    if (selectedIndex < images.length - 1) {
      setSelectedIndex(selectedIndex + 1);
      setSelectedImage(images[selectedIndex + 1]?.file);
    }
  };

  const prevImage = () => {
    if (!selectedTag) return;
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
      setSelectedImage(galleryData[selectedTag][selectedIndex - 1]?.file);
    }
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="gallery-container" style={{ paddingTop: "90px", paddingLeft: "30px" }}>
          <h2 style={{ textAlign: "left" ,paddingBottom:"20px"}}>Gallery</h2>
          {Object.keys(galleryData)
            .filter((tag) => galleryData[tag].length > 0)
            .map((tag, index) => (
              <div key={index} className="gallery-section"style={{textAlign:"left",paddingBottom:"30px"}}>
                <h3>{tag}</h3>
                <div className="gallery-grid" >
                  {galleryData[tag].map((image, idx) => (
                    <img
                      key={idx}
                      src={`data:image/png;base64,${atob(image.file)}`}
                      alt={image.name}
                      className="gallery-image"
                      style={{
                        width: "250px",
                        height: "180px",
                        cursor: "pointer",
                        objectFit: "cover",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                        margin:"15px"
                      }}
                      onClick={() => openImageModal(tag, idx)}
                    />
                  ))}
                </div>
              </div>
            ))}
          {selectedImage && (
            <div
              className="modal-overlay"
              onClick={() => setSelectedImage(null)}
              style={{
                position: "fixed",
                top: 0,
                left: "245px",
                width: "calc(100% - 245px)",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              {selectedIndex > 0 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    prevImage();
                  }}
                  style={{
                    position: "absolute",
                    left: "10px",
                    fontSize: "30px",
                    background: "none",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  &#9664;
                </button>
              )}
              <img
                src={`data:image/png;base64,${atob(selectedImage)}`}
                alt="Enlarged"
                style={{ maxWidth: "60%", maxHeight: "90%", borderRadius: "10px" }}
              />
              {selectedTag && selectedIndex < galleryData[selectedTag].length - 1 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    nextImage();
                  }}
                  style={{
                    position: "absolute",
                    right: "10px",
                    fontSize: "30px",
                    background: "none",
                    border: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  &#9654;
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default Gallery;
